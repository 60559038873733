import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { MenuLayout } from '../../components/menu-layout.component';
import { FooterNavigation } from '../../components/footer-navigation.component';
import StepInstruction from '../../components/step-instruction.component';

const steps = [
    { step: "1. 唾液採取容器セットに入っているコレクションチューブに、ファネルをしっかりと取り付けます。", stepImg: "/img/steps/step2-1.jpg" },
    { step: "2. ゆっくりと唾液を吐き出します。", stepImg: "/img/steps/step2-2.jpg" },
    { step: "3. 唾液は1ml採取してください。泡など入らないように、なるべく気をつけてください。", stepImg: "/img/steps/step2-3.jpg" },
    { step: "4. 唾液採取容器セットに入っている郵送用試薬をコレクションチューブに入れます。", stepImg: "/img/steps/step2-4.png" },
    { step: "5. ファネル部分を取り外し、キャップをしっかりとねじ込んで締めてください。ファネル部分は、通常の家庭ごみとして廃棄してください。", stepImg: "/img/steps/step2-5.png" },
    { step: "6. 5回ほど転倒混和して（上下逆さまにしたり戻したりを繰り返し混ぜて）ください。", stepImg: "/img/steps/step2-6.png" },
];

export default function Home() {
    const { navigate, t } = useI18next();

    return <MenuLayout className="take-test step-2">
        <div className="section-container animateIn">
            <div className="section v-center">
                <h3 className="t-subheading2">{t('STEP 2')}</h3>
                <h4 className="t-h2">{t('Saliva collection')}</h4>
            </div>
            <div className="section section--steps">
                {steps.map((step, index) => (
                    <StepInstruction key={index} step={step.step} stepImg={step.stepImg} />
                ))}
            </div>
        </div>
        <FooterNavigation
            variant="orange"
            steps={3}
            activeStep={1}
            label={t('Next')}
            onClick={() => navigate('/test/step3')}
        />
    </MenuLayout>;
}
